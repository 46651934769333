* {
  transition: all 0.5s;
}

.image {
  filter: grayscale(100%);
  width: 10%;
}

.boastTitle {
  opacity: 60%;
}

.boastContainer:hover > .boastTitle {
  opacity: 100%;
}

.boastContainer:hover > .image {
  filter: grayscale(0%);
}

.login-page {
  background-image: url("./Assets/LoginPage/background.svg");
  background-repeat: no-repeat;
  height: 100vh;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

/* navbar */
.navbar-links {
  list-style-type: none;
  display: flex;
}

.navbar-links li a {
  display: flex;
  gap: 4px;
  text-decoration: none;
  color: #444;
  padding: 20px 20px;
  font-weight: 700;
  transition: 0.4s all;
}

.navbar-links li.navbar-dropdown {
  position: relative;
  padding: 0px 20px;
  /* white-space: nowrap; */
}

.navbar-links li.navbar-dropdown .dropdown {
  /* visibility: hidden; */
  display: none;
  opacity: 0;
  position: absolute;
  padding: 0 20px 0 20px;
  top: 100%;
  transform: translateY(50px);
  left: 23px;
  /* width: 300px; */
  background-color: #fff;
  box-shadow: 0px 20px 10px 1px rgba(0, 0, 0, 0.141);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  z-index: 111;
  transition: all 1000ms;
}

.navbar-links li.navbar-dropdown:hover .dropdown {
  /* visibility: visible; */
  opacity: 1;
  transform: translateY(0px);
  display: block;
  transition: all 1000ms;
  transition-delay: 2000ms;
  /* border-radius: 8px; */
}

@media (max-width: 768px) {
  .navbar-links li.navbar-dropdown .dropdown {
    display: none;
    position: relative;
    width: 100%;
    white-space: wrap;
    left: 0px;
  }

  .navbar-links li.navbar-dropdown:hover .dropdown {
    display: block;
  }
  .navbar-links li.navbar-dropdown {
    position: relative;
    padding: 0px 0px;
    /* white-space: nowrap; */
  }
}

.navbar-links li.navbar-dropdown .dropdown a {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 400;
}

.navbar-dropdown .dropdown a:hover {
  padding-left: 30px;
  font-weight: 600 !important;
}

.navbar-links li a:hover {
  color: #0169c2;
}
/* a:hover {
  background-color: #6681984a;
} */

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 90vh;
  /* background-color: #f00; */
  background-image: url("http://lorempixel.com/1366/698/food/");
  background-size: cover;
}

.header-inner {
  text-align: center;
  color: #0169c2;
  text-shadow: 0px 10px 10px rgba(0, 0, 0, 0.8);
}

.header-inner h1 {
  font-family: "Great Vibes", cursive;
  font-size: 130px;
}

.header-inner form input[type="search"] {
  position: relative;
  width: 500px;
  border: none;
  padding: 15px;
  border-radius: 27px;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.3);
  z-index: 11;
}

.header-inner form input[type="search"]:focus {
  outline: none;
}

.header-inner form input[type="search"]:focus + div {
  z-index: 1;
  opacity: 1;
}

.image-upload-container {
  position: relative;
  width: 800px;
  /* Adjust the width as needed */
  height: 120px;
  /* Adjust the height as needed */
  overflow: hidden;
  border: 2px solid #ddd;
  border-radius: 5px;
  background-color: #f8faff;
}

.file-input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.upload-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.selected-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.blogContent img {
  margin: auto;
}

/* scrollbar */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 0, 0, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-button {
  display: none;
}

.capabiltitescard {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
